import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [lightTheme, setLightTheme] = useState(true); // True is light, False is dark

  function toggle() {
    // setLightTheme((prevLightTheme) => {
    //   return setLightTheme(!prevLightTheme);
    // });
  };

  return (
    <header>
      <div className="top-header">
        <img id="ivoted" src="/images/ivoted.jpg" />
        <span id="title">Nifty Nuggets</span>
        {/* <button onClick={toggle}>night</button> */}
      </div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            {/* <li><Link to="/terms">Terms</Link></li> */}
            <li><Link to="/privacy">Privacy</Link></li>
            {/* <li><a href="https://mint.niftynuggets.org">Mint</a></li>
            <li><a href="https://verify.niftynuggets.org">Verify</a></li>*/}
          </ul>
        </nav>
    </header>
  );
};

export default Header;