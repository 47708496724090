import React from 'react';

function Footer() {

    var today = new Date();
    var year = today.getFullYear();

    return (
        <footer>
            <nav>
                <ul>
                    <li><a href="https://twitter.com/NuggetsNifty" target="_blank">Twitter</a></li>
                    <li><a href="https://discord.gg/VJR7dypNr5" target="_blank">Discord</a></li>
                    <li><a href="https://opensea.io/collection/niftynuggetsofficial" target="_blank">Openesea</a></li>
                    <li><a href="https://blur.io/collection/niftynuggetsofficial" target="_blank">Blur</a></li>
                    <li>© {year} by Nifty Nuggets.</li>
                </ul>
            </nav>
        </footer>
    );
}

export default Footer;