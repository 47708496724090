import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Header from './components/header.component'
import Home from './components/home.component'
import Privacy from './components/privacy.component'
import Footer from './components/footer.component'
import './App.css';

function App() {
  return (
    <div className="container col-xl-10 col-xxl-8">
      <div className="row align-items-center g-lg-5 py-5">
      <BrowserRouter basename="/">
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home/>} /> 
          <Route path="/privacy" element={<Privacy/>} />
        </Routes>
      </BrowserRouter>
      <Footer/>
      </div>
    </div>
  );
}

export default App;
