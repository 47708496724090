import * as React from 'react';

const Home = () => {
    return (
      <div className="index-page">
        <h1>This website does not exist, nothing to see here!</h1>
        <p>Nifty Nuggets are: [250+250+250+250] <b>Unique NFT's</b> originally on <span className="greenish">Immutable X</span>, the Gas less Ethereum Layer 2 solution for NFT's, but now we migrated to <span className="greenish">ETH L1</span>, you can check it on <a href="https://opensea.io/collection/niftynuggetsofficial" target="_blank">Opensea</a> (for example). </p>
        <p>Nuggets will be accompanied by <b>$SAUCE</b> (somewhere in the future) because Nuggets are just better with sauce, everybody agrees!</p>
        <p>This project aims to bring value to its small, exclusive, community of Nugget-loving
        degens. Keep an eye out on Lord Nugget in the Discord server, heaps of important
        (you do NOT want to miss) information will be released there.</p>
        <p>The Nuggets do not need a website at all, come hang out with us in the Discord now,
        we have loads of !work to do there unlike on this non existent website, we'll make
        sure you know when it's time to connect wallets and press fry!</p>
        <p>Not on or Discord Server yet? The doors are closed and we are not letting anymore
        people in for now. We're keeping the community small on purpose but follow our
        Twitter and have notifications on as the Nuggets might take a vote and decide that
        they want to let in a few more people! Until then all you can do is dream about all
        things Nuggets, could be worse!</p>
      </div>
    )
  }

export default Home;
